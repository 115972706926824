import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { LazyLoadComponent } from "react-lazy-load-image-component"
// import PartnerSwiper from "../components/swiper/swiper-partner.js"
import { IoIosPeople } from "react-icons/io"
import { RiUserHeartLine } from "react-icons/ri"
import { RiTimerFlashFill } from "react-icons/ri"
import { FaUserClock } from "react-icons/fa"
// import Cases from "../components/cases"
import Banner from "../components/hero/tech-banner"
// import Testimonials from "../components/swiper/testimonials-new"
import loadable from "@loadable/component"
import pMinDelay from "p-min-delay"

const PartnerSwiper = loadable(() =>
  pMinDelay(import("../components/swiper/swiper-partner.js"), 500)
)

const Testimonials = loadable(() =>
  pMinDelay(import("../components/swiper/testimonials-new"), 500)
)

const Cases = loadable(() => pMinDelay(import("../components/cases"), 500))

class QuandaryBuildPage extends React.Component {
  render() {
    return (
      <Layout banner={<Banner />} location={this.props.location}>
        <Seo
          title="IT and Low-Code Consulting | Quandary Consulting Group"
          description="We help mid market and enterprise organizations get more out of their low-code applications and improve operations. Denver-Based. Learn more."
        />

        <PartnerSwiper />

        <section style={{ padding: "3rem 0", backgroundColor: "#e7e7e7" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="intro mb-4">
                  <h2 className="mt-0">Build Better Business Systems</h2>
                </div>
                <p className="mt-3">
                  "Good enough" won't last. Relying on a patchwork of disparate
                  applications and spreadsheets to run your business only leads
                  to more problems. Not more business. Struggling to meet
                  deadlines and deliver on demands isn't the norm for any
                  growing business. You shouldn't have to live with increased
                  waste, more mistakes, and delayed projects. And you don't have
                  to.
                </p>
                <p>
                  Quandary Consulting Group provides technology consulting
                  services designed to improve how your business captures,
                  stores, and uses data. Team up with us and see which systems
                  you should invest in to scale your growing business. We'll
                  help you reduce risk, cost, frustration, and time to market by
                  squeezing every ounce of efficiency out of your application
                  stack.
                </p>

                <div className="text-left my-2">
                  <Link
                    className="btn btn-primary btn-arrow mt-3"
                    to="/contact/"
                  >
                    Let's Chat
                  </Link>
                </div>
              </div>
              <div className="col-lg-6 pt-5">
                <LazyLoadComponent>
                  <iframe
                    width="100%"
                    height="380px"
                    src="https://www.youtube.com/embed/UluLiLCI72c"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullscreen
                    title="Technology Consulting"
                    className="rounded"
                  />
                </LazyLoadComponent>
              </div>
            </div>
          </div>
        </section>

        <section
          className="bg-white quandary-connect-glance"
          style={{ padding: "3rem 0" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <h2>Transform How Your Business Functions</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-6 mt-5 text-center">
                <RiUserHeartLine />
                <p className="h5 my-4" style={{ fontWeight: "bold" }}>
                  Reduce Risk
                </p>
                <p>
                  Deploy secure, reliable systems that streamline everything.
                </p>
              </div>
              <div className="col-lg-3 col-md-6 mt-5 text-center">
                <IoIosPeople />
                <p className="h5 my-4" style={{ fontWeight: "bold" }}>
                  Improve Results
                </p>
                <p>
                  Overdeliver on fast turnarounds without burning out your team.
                </p>
              </div>
              <div className="col-lg-3 col-md-6 mt-5 text-center">
                <RiTimerFlashFill />
                <p className="h5 my-4" style={{ fontWeight: "bold" }}>
                  Stay Agile
                </p>
                <p>
                  Adjust to changing markets rapidly and outperform your
                  competitors.
                </p>
              </div>
              <div className="col-lg-3 col-md-6 mt-5 text-center">
                <FaUserClock />
                <p className="h5 my-4" style={{ fontWeight: "bold" }}>
                  Eliminate Inefficiency
                </p>
                <p>Drastically cut costs and waste business-wide.</p>
              </div>
            </div>
          </div>
        </section>

        <section id="case-studies-container">
          <div id="case-studies" className="bg-indigo">
            <div className="container">
              <div className="intro text-center">
                <div className="row">
                  <div className="col-md-10 mx-auto">
                    <p className="h5 text-light-blue text-uppercase font-weight-bold m-0">
                      Case Studies
                    </p>
                    <h2 className=" my-2 text-white">
                      We Save Clients 1000s of Hours. Every Year.
                    </h2>
                    {/* <p className="text-white">
                      We helped a national healthcare provider save over 9,000
                      hours in weekly labor costs by developing a single
                      application...
                    </p> */}
                  </div>
                </div>
              </div>
            </div>

            <Cases allSanityCaseStudy={this.props.data.allSanityCaseStudy} />
          </div>
        </section>

        <section
          id="it-consulting"
          className="it-consulting"
          style={{ padding: "0 0 3rem 0" }}
        >
          <div className="container pt-5">
            <div className="row">
              <div className="col-lg-12 text-center">
                <h2>Technology Consulting Services</h2>
              </div>
            </div>
            <div
              className="row align-items-center flex-column-reverse flex-lg-row flex-md-row"
              style={{
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px",
                marginTop: "3rem",
              }}
            >
              <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 text-center mb-lg-0 mb-md-0 mb-4 px-md-5 text-left-mobile">
                <h3>Streamline Business Processes</h3>
                <p className="mt-4">
                  Eliminate unnecessary slowdowns and bottlenecks with automated
                  workflows.
                </p>
              </div>
              <div className="col-lg-6 col-md-6 py-lg-3 py-3">
                <StaticImage
                  placeholder="blurred"
                  className="rounded d-flex"
                  src="../images/new-images/optimize-business.jpeg"
                  alt="Discover unknown problems"
                  layout="constrained"
                  loading="lazy"
                />
              </div>
            </div>
            <div className="row align-items-center mt-3">
              <div className="col-lg-6 col-md-6 py-lg-4 py-3">
                <StaticImage
                  placeholder="blurred"
                  className="rounded d-flex"
                  src="../images/new-images/optimize-butterfly.jpeg"
                  alt="Transform without the overhaul"
                  layout="constrained"
                  loading="lazy"
                />
              </div>
              <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 mt-0 text-center mb-lg-0 mb-md-0 mb-3 px-md-5 text-left-mobile">
                <h3>Optimize Your Application Stack</h3>
                <p className="mt-4">
                  Get the most out of your software suite with industry-specific
                  insights and ongoing support.
                </p>
              </div>
            </div>
            <div
              className="row align-items-center flex-column-reverse flex-lg-row flex-md-row mt-3"
              style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
            >
              <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 text-center mb-lg-0 mb-md-0 mb-4 px-md-5 text-left-mobile">
                <h3 className="text-left-mobile">
                  Experience Clear Data Dashboarding
                </h3>
                <p className="text-left-mobile mt-4">
                  Get clear data reporting in an accessible dashboard that
                  provides actionable insights at a glance.
                </p>
              </div>
              <div className="col-lg-6 col-md-6 py-lg-3 py-3">
                <StaticImage
                  placeholder="blurred"
                  className="rounded d-flex"
                  src="../images/new-images/manage-data-simplistically.png"
                  alt="Manage Data Simplistically"
                  layout="constrained"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </section>

        <Testimonials />

        <section
          style={{
            padding: "3rem 0",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-10 mx-auto text-center">
                <h2 className="text-left-mobile">
                  Get More Out of Your Business Applications
                </h2>
                <p className="text-left-mobile">
                  We help you get more out of your tech, so you can complete
                  your projects faster, exceed your goals, and build the
                  business your customers desire.
                </p>
                <Link
                  aria-label="link"
                  className="speak-to-btnx btn btn-primary btn-arrow mt-3"
                  to="/contact/"
                >
                  Speak to a Technology Consultant
                </Link>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default QuandaryBuildPage

export const techConsultingPageQuery = graphql`
  query techConsultingPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityCaseStudy(sort: { fields: [publishedAt], order: DESC }, limit: 3) {
      edges {
        node {
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          bannerImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`
